import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { Settings } from 'luxon'
import posthog from 'posthog-js'

Settings.defaultZone = 'America/New_York'

if (environment.production) {
    enableProdMode()

    if (window && window.console) {
        const emptyFunc = (): void => {
        }

        window.console.log = emptyFunc
        window.console.error = emptyFunc
        window.console.warn = emptyFunc
        window.console.dir = emptyFunc
        window.console.trace = emptyFunc
        window.console.time = emptyFunc
    }
}

posthog.init(
    import.meta.env['VMKL_PUBLIC_POSTHOG_KEY'],
    {
        api_host: import.meta.env['VMKL_PUBLIC_POSTHOG_HOST'],
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    }
)

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err))