import { Component } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'

@Component({
    selector: 'app-screenshot-slider',
    templateUrl: './screenshot-slider.component.html',
    styleUrls: ['./screenshot-slider.component.scss']
})
export class ScreenshotSliderComponent {
    files: string[]
    currentIndex = 0
    clientUrl = import.meta.env['VMKL_PUBLIC_CLIENT_URL']
    nextTimer?: any

    constructor(private cookieService: CookieService) {
        this.files = [
            'club33',
            'finalepeprally1',
            'grogumaze1',
            'hallo2023',
            'hostevent',
            'hostevent2',
            'jaydeebgr',
            'larkstitch',
            'lop-afterparty',
            'lop-pirates',
            'madhater',
            'scr6',
            'scr10',
            'scr11',
            'vjblueroom',
            'waterparkpool'
        ]
        this.files.sort(() => 0.5 - Math.random())
        this.files.unshift('tianas')
        this.setNextTimer()
    }

    asIsSort(): number {
        return 0
    }

    setNextTimer(): void {
        clearTimeout(this.nextTimer)

        this.nextTimer = setTimeout(() => {
            this.nextImage()
        }, 7000)
    }

    nextImage(): void {
        if (this.currentIndex === this.files.length - 1) {
            this.currentIndex = 0
        } else {
            this.currentIndex++
        }

        this.setNextTimer()
    }

    backImage(): void {
        if (this.currentIndex === 0) {
            this.currentIndex = this.files.length - 1
        } else {
            this.currentIndex--
        }
        this.setNextTimer()
    }
}